<template>
  <v-main>
    <v-container class="mt-8 mb-12">
      <div class="text-h4 mb-8">Wichtige und hilfreiche Tipps für deine Karriere</div>
      <p>Hier findest du nützliche Downloads, von den Handouts aus unseren Seminaren, bis hin zu aktuellen Artikeln zum
        Thema Karriere und Persönlichkeitsentwicklung.</p>
      <v-card class="mt-8">
        <v-card-title>Handouts Karriere-Seminar</v-card-title>
        <v-card-text>
          <p>
            <v-btn outlined href="https://www.daskarriereinstitut.de/wp-content/uploads/2020/02/Selbsteinscha%CC%88tzung-vs.-Fremdeinsch%C3%A4tzung.pdf" target="_blank">Selbsteinschätzungsbogen</v-btn>
          </p>
          <p>
            <v-btn outlined href="https://www.daskarriereinstitut.de/wp-content/uploads/2020/02/Musterbewerbungsunterlagen.pdf" target="_blank">Musterbewerbungsunterlagen</v-btn>
          </p>
          <p>
            <v-btn outlined href="https://www.daskarriereinstitut.de/wp-content/uploads/2020/02/AIDA-Formel.pdf" target="_blank">AIDA Formel</v-btn>
          </p>
          <p>
            <v-btn outlined href="https://www.daskarriereinstitut.de/wp-content/uploads/2020/02/Dress-for-Success.pdf" target="_blank">Dress for Success</v-btn>
          </p>
          <p>
            <v-btn outlined href="https://www.daskarriereinstitut.de/wp-content/uploads/2020/02/Tipps-zum-Assesment-Center.pdf" target="_blank">Tipps zum Assessment Center</v-btn>
          </p>
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>Artikel</v-card-title>
        <v-card-text>
          <p>
            <v-btn outlined href="https://www.stepstone.de/wissen/gehaltsreport-2020/#:~:text=StepStone%20Gehaltsreport%202020,die%20Top%2DVerdiener%202020%20sind!" target="_blank">Stepstone Gehaltsreport 2020</v-btn>
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Downloads"
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>